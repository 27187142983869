import React from 'react'
import { Box, Button, Container, Heading } from 'atomic'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'
import * as css from './RelatedPostsPages.css'
import { Link } from 'components/link/Link'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import useFetchRelatedPosts from 'hooks/useFetchRelatedPosts'
import BlogArticlePreview from 'components/builder_components/blog_components/blog_article_preview/BlogArticlePreview'

const RelatedPostsPages: React.FC<{ relatedPosts: string[] }> = ({ relatedPosts }) => {
  const { t } = useSafeTranslation()
  const { data } = useFetchRelatedPosts(relatedPosts)

  if (!data || data.length === 0) return null

  return (
    <Box backgroundColor={'lav4'} position={'relative'} style={{ zIndex: 1 }}>
      <Container display={'flex'} flexDirection={'column'} gap={'xl'} alignItems={'center'} justifyContent={'center'} paddingY={'xxl'}>
        <Box position={'relative'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
          <Heading as={'h3'} fontFamily={'accent'} fontSize={'xxl'} fontWeight={'normal'} lineHeight={'xs'}>
            Read more Stories
          </Heading>
          <Illustration name={'underline'} className={css.underline} />
        </Box>
        <Box className={css.relatedBoxes} gap="m">
          {data?.map((post, i) => (
            <BlogArticlePreview key={i} article={post.data} startDate={post?.startDate} createdDate={post?.createdDate} fixedImageHeight />
          ))}
        </Box>
        <Link href={'/stories'}>
          <Button variant="primary" marginBottom={'none'}>
            {t('Momsstory_campaign_site_stories_cta_button')}
          </Button>
        </Link>
      </Container>
    </Box>
  )
}

export default RelatedPostsPages
