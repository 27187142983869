import { gql } from '@apollo/client'
import { useEffect, useState } from 'react'
import { builderClient } from 'utils/api-client'

export type BlogData = {
  title: string
  intro: string
  slug: string
  image: string
  imagePosition: string
  date: string
  tag: string[]
  categories: string[]
  readingTime: number
  createdDate?: number
  startDate?: number
}

type BlogArticle = {
  data: BlogData
  id: string
  createdDate: number
  startDate: number
}

const useFetchRelatedPosts = (posts: string[]) => {
  const [data, setData] = useState<BlogArticle[]>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const relatedPosts = await builderClient.query({
          query: gql`
            {
              blogArticle(query: {
                id: "{$in: ${JSON.stringify(posts.map((post) => post)).replace(/"/g, '\\"')}}"
              }) {
                data {
                  title
                  intro                  
                  slug
                  image
                  imagePosition                 
                  author
                  tag
                  categories
                }
                id
                createdDate
                startDate
              }
            }
          `,
        })

        setData(relatedPosts?.data?.blogArticle)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }
    if (posts?.length > 0) {
      fetchData()
    }
  }, [posts])

  return { data, loading, error }
}

export default useFetchRelatedPosts
