import { ThemeColors } from 'atomic/styles/theme.css'

export interface Category {
  id: number
  name: string
  color: keyof ThemeColors
  borderColor: keyof ThemeColors
}

export const Categories: { [key: string]: Category } = {
  inspiration: { id: 1, name: 'Inspiration', color: 'ljung2', borderColor: 'ljung3' },
  realLifeStories: { id: 2, name: 'Real life stories', color: 'blåbär2', borderColor: 'blåbär3' },
  ourFootprint: { id: 3, name: 'Our footprint', color: 'mossa2', borderColor: 'mossa3' },
  behindTheScenes: { id: 4, name: 'Behind the scenes', color: 'älv2', borderColor: 'älv3' },
  meetAOnceUponer: { id: 5, name: 'Meet a Once Uponer', color: 'hjortron2', borderColor: 'hjortron3' },
}
